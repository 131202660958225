import { motion } from "framer-motion"

export function Logged() {

  const containerVariants = {
    hidden: {
      opacity: .5,
      width: 400,
    },
    visible: {
      opacity: 1,
      width: 400,
      transition: {
        type: 'spring', 
        stiffness: 50, // Lower stiffness
        ease: 'easeInOut' 
      }
    }
  };

  return (
    <div className="main">
      <motion.div 
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="logged-container"
      >
        <h1>You're good to go! (●'◡'●)</h1>
        <p>You can return to your app now.</p>
      </motion.div>
    </div>
  );
}
