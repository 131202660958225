import { motion } from "framer-motion"
import React, { useContext, useState } from 'react';
import { SessionContext } from "../context/session";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoDisturbOnRoundedIcon from '@mui/icons-material/DoDisturbOnRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useLocation, useNavigate } from "react-router-dom";
import { enqueueSnackbar } from 'notistack'
import CircularProgress from '@mui/material/CircularProgress';


export function Authorize() {

  const { state } = useContext(SessionContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [ isLoading, setIsLoading ] = useState(false);

  const grants = {
    "profile.read": "Enma wants to read your profile",
    "favorites.all": "Enma wants to have full access to your favorites",
    "stats.all": "Enma wants to have full access to your stats",
  }

  const containerVariants = {
    hidden: {
      opacity: .5,
      width: 750,
    },
    visible: {
      opacity: 1,
      width: 450,
      transition: {
        type: 'spring', 
        stiffness: 50, // Lower stiffness
        ease: 'easeInOut' 
      }
    }
  };

  const GrantsComponent = () => {
    const requested = state.scopes;
    return (
      <ul>
        {requested.map((grant, index) => {
          return (
            grants[grant] &&
            <li key={index} className="allow-item">
              <CheckCircleIcon />
              {grants[grant]}
            </li>
          )
        })}
        <li index={1000} className="deny-item">
          <DoDisturbOnRoundedIcon />
          Enma doesn't want to be your waifu.
        </li>
      </ul>
    )
  }

  const createCallbackError = (msg) => {
    enqueueSnackbar(msg ?? "An error occurred while redirecting to callback!", {
      variant: 'error',
      hideIconVariant: true
    })
  }

  const grantAccess = async () => {
    setIsLoading(true);
    const callbackUri = state.callback_uri;
    const redirectToCallback = state.callback_redirect

    if(!callbackUri) {
      setIsLoading(false);
      return createCallbackError("Callback URI is missing!");
    }

    if (redirectToCallback === 'true') {
      return navigate(`${callbackUri}?code=${location.state.code}`)
    }

    return fetch(`${callbackUri}?code=${location.state.code}`, {
      method: 'GET'
    }).then(async response => {
      if(response.status !== 200) {
        return createCallbackError();
      }
      navigate('/oauth/logged');
    }).catch(() => {
      return createCallbackError();
    }).finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <div className="main">
      <motion.div 
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="authorize-container"
      >
        <div className="authorize-header">
          <div className="app-logo-container">
            <div className="app-logo-holder">
              <img 
                className="app-logo-img"
                src="https://github.com/AlexandreSenpai/Enma/raw/master/docs/images/enma.png"
              />
            </div>
            <MoreHorizIcon />
            <div className="app-logo-holder">
              <img 
                className="app-logo-img"
                src="/images/luis.png"
              />
            </div>
          </div>
          <div className="app-description">
            <p>Enma wants to have access to your account!</p>
          </div>
        </div>
        <div className="authorize-body">
          <div className="authorize-section">
            <div className="permissions-header">
              <p>Permissions</p>
            </div>
            <div className="permissions-body">
              <GrantsComponent />
            </div>
          </div>
          <div className="authorize-section">
            <div className="authorize-disclaimer">
              <p>
                By clicking Allow, you allow this app to use and modify your account based on the given permissions listed above. You can change this and other Account Permissions at any time.
              </p>
            </div>
          </div>
        </div>
        <div className="authorize-footer">
          <div className="authorize-buttons">
            <button className="deny-button">
              Deny
            </button>
            <button 
              className="allow-button"
              onClick={grantAccess}
            >
              { isLoading ? <CircularProgress color="inherit" size={16} /> : "Allow" }
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
