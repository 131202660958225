import React, { createContext } from 'react';

export const SessionContext = createContext(null);

export const SessionProvider = ({ children }) => {
    const [state, setState] = React.useState({
        callback_uri: '',
        callback_redirect: 'false',
        scopes: [],
        state: '',
        response_type: '',
        client_id: '',
        code_challenge: '',
        code_challenge_method: ''
    });

    const [sessionId, setSessionId] = React.useState(null);

    const convertScopesToArray = (scopes) => {
        return scopes?.split(' ');
    }

    const parseState = (stateInfo) => {
        const newState = {
            ...state
        }

        for(const key of stateInfo.keys()) {
            if(key === 'scope') {
                newState['scopes'] = convertScopesToArray(stateInfo.get(key));
                continue;
            }
            let value = stateInfo.get(key);
            newState[key] = value;
        }

        setState((currState) => ({
            ...currState,
            ...newState
        }))

        return newState;
    }

    const createSession = async (session) => {

        const parsed = parseState(session);

        await fetch("https://auth.shogun.minorin.io/session", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: 'include',
            mode: 'cors',
            body: JSON.stringify(parsed)
        });

    }

    const getSession = async (id) => {
        return fetch(`https://auth.shogun.minorin.io/session?id=${id}`, {
            method: "GET"
        }).then(response => {
            console.log(response)
            return response;
        }).catch(err => console.log(err))
    }

    return <SessionContext.Provider value={{ state, sessionId, createSession, getSession }}>
        {children}
    </SessionContext.Provider>

}