import {
  createBrowserRouter
} from "react-router-dom";
import { Login } from '../pages/login'
import { Authorize } from "../pages/authorize";
import { Signup } from "../pages/signup";
import { Logged } from "../pages/logged";

export const router = createBrowserRouter([
  {
    path: "/oauth/login",
    element: <Login />,
  },
  {
    path: "/oauth/authorize",
    element: <Authorize />,
  },
  {
    path: "/oauth/signup",
    element: <Signup />,
  },
  {
    path: "/oauth/logged",
    element: <Logged />,
  },
]);
  