import { motion } from "framer-motion"
import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { SessionContext } from '../context/session';
import { enqueueSnackbar } from 'notistack'
import CircularProgress from '@mui/material/CircularProgress';

export function Login() {

  const navigate = useNavigate();
  const { createSession, getSession } = useContext(SessionContext);
  const [ searchParams ] = useSearchParams();
  const [ tips ] = useState([
    "Did you know that Enma's full name is Minori Enma?",
    "Did you know that Enma's is a big fan of Oshino Shinobu?",
    "Did you know that Enma's favorite color is purple?",
    "Did you know that Enma's hobby is reading?",
    "Did you know that there's other Goddesses like Enma?"
  ]);
  const [ currentTip, setCurrentTip ] = useState(0);
  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {
    createSession(searchParams);
  }, [])

  useEffect(() => {
    setCurrentTip((tip) => {
      const tipIndex = Math.floor(Math.random() * tips.length)
      if(tip === tipIndex) {
        return 0;
      }
      return tipIndex;
    })
  }, [])

  const [formData, setFormData] = useState({
    password: '',
    email: '',
  });
  
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const containerVariants = {
    hidden: {
      opacity: .8, 
      scale: 0.97 // Slightly smaller start
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        type: 'spring', 
        stiffness: 50, // Lower stiffness
        duration: 0.95, // Increased duration
        ease: 'easeInOut' 
      }
    }
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    fetch('https://auth.shogun.minorin.io/oauth/login', {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Authorization: `Basic ${btoa(formData.email + ":" + formData.password)}`,
        'Content-Type': 'application/json'
      }
    }).then(async response => {
      const data = await response.json();
      if(response.status !== 200) {
        enqueueSnackbar(data.message, {
          variant: 'error',
          hideIconVariant: true
        })
        return;
      }

      navigate(`/oauth/authorize`, {
        state: { code: data.code }
      });

    }).catch(error => {
      enqueueSnackbar("An error occurred while logging in!", {
        variant: 'error',
        hideIconVariant: true
      })
    }).finally(() => {
      setIsLoading(false);
    })
  };

  return (
    <div className="main">
      <motion.div 
        className="login-container"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="login-form">
          <div className="welcome-holder">
            <h1>Welcome Reader!</h1>
            <p>{tips[currentTip]}</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="input-holder">
              <label htmlFor="email">E-MAIL</label>
              <input 
                tabIndex={1} 
                id="email" 
                type="email" 
                name="email"
                value={formData.email} 
                onChange={handleChange}
                placeholder="Enter your e-mail"
              />
            </div>
            <div className="input-holder">
              <label htmlFor="password">PASSWORD</label>
              <input 
                tabIndex={2} 
                id="password" 
                type="password" 
                name="password"
                value={formData.password} 
                onChange={handleChange}
                placeholder="Enter your super-secure password!" 
              />
            </div>
            <div className="forgot-password">
              <a href="#" tabIndex={3}><b>Forgot your password?</b></a>
            </div>
            <button tabIndex={4} type="submit">{ isLoading ? <CircularProgress color="inherit" size={16} /> : "ENTER" }</button>
            <div className="create-account">
              <p>Dont you have an account yet?</p><a onClick={() => navigate('/oauth/signup')} tabIndex={5}><b>Signup</b></a>
            </div>
          </form>
        </div>
        <div className="login-image">
          <img src="/images/enma.gif" alt="Minori Enma" />
        </div>
      </motion.div>
    </div>
  );
}
