import { motion } from "framer-motion"
import React, { useState } from 'react';
import { enqueueSnackbar } from 'notistack'
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';

export function Signup() {

  const navigate = useNavigate();
  const [ isLoading, setIsLoading ] = useState(false);
  const [formData, setFormData] = useState({
    password: '',
    email: '',
    username: ''
  });
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    fetch('https://auth.shogun.minorin.io/oauth/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    }).then(async response => {

      if (response.status !== 201) {
        const data = await response.json();
        enqueueSnackbar(data.message, {
          variant: 'error',
          hideIconVariant: true
        });
        return;
      }

      enqueueSnackbar("Account created successfully!", {
        variant: 'success',
        hideIconVariant: true,
        autoHideDuration: 1500,
        onClose: () => navigate('/oauth/login')
      });

    }).catch(error => {
      enqueueSnackbar("An error occurred while creating the account!", {
        variant: 'error',
        hideIconVariant: true
      });
    }).finally(() => {
      setIsLoading(false);
    })
  };

  const containerVariants = {
    hidden: {
      opacity: .5,
      width: 750,
    },
    visible: {
      opacity: 1,
      width: 400,
      transition: {
        type: 'spring', 
        stiffness: 50, // Lower stiffness
        ease: 'easeInOut' 
      }
    }
  };

  return (
    <div className="main">
      <motion.div 
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="authorize-container"
      >
        <div className="signup-header">
            <h1>Create Account!</h1>
        </div>
        <form className="signup-form" onSubmit={handleSubmit}>
            <div className="input-holder">
              <label htmlFor="email">E-MAIL</label>
              <input 
                tabIndex={1} 
                id="email" 
                type="email" 
                name="email"
                value={formData.email} 
                onChange={handleChange}
                placeholder="Enter your e-mail"
              />
            </div>
            <div className="input-holder">
              <label htmlFor="username">USERNAME</label>
              <input 
                tabIndex={2} 
                id="username" 
                type="text" 
                name="username"
                value={formData.username} 
                onChange={handleChange}
                placeholder="Enter your user name"
              />
            </div>
            <div className="input-holder">
              <label htmlFor="password">PASSWORD</label>
              <input 
                tabIndex={3} 
                id="password" 
                type="password" 
                name="password"
                value={formData.password} 
                onChange={handleChange}
                placeholder="Enter your super-secure password!" 
              />
            </div>
            <button tabIndex={4} type="submit">{ isLoading ? <CircularProgress color="inherit" size={16} /> : "CREATE ACCOUNT" }</button>
            <div className="create-account">
              <p>Already has an account?</p><a onClick={() => navigate('/oauth/login')} tabIndex={5}><b>Login</b></a>
            </div>
          </form>

      </motion.div>
    </div>
  );
}
